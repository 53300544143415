import React, { useState, useRef, useEffect } from 'react';
import SliderCard from '../molecules/SliderCard';
import './Carousel.css';

const Carousel = ({
  slides,
  autoplay = true,
  autoplaySpeed = 15, // Speed in seconds for a complete scroll
  className = "custom-slider",
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const sliderRef = useRef(null);
  const requestRef = useRef();

  const [extendedSlides, setExtendedSlides] = useState([...slides]); // extendedSlides
  

  const handleScroll = () => {
    if (!sliderRef.current) return;
  
    const { scrollWidth, clientWidth, scrollLeft } = sliderRef.current;
  
    // Calculate how close we are to the end of the scroll in percentage
    const maxScrollLeft = scrollWidth - clientWidth; // Maximum possible scroll value
    const scrollPercentage = (scrollLeft / maxScrollLeft) * 100; // Current scroll position in percentage
  
    console.log(`Scroll Percentage: ${scrollPercentage}%`); // Log or use this value as needed
  

    if (scrollPercentage == 100) {
      // Logic to dynamically add slides could go here
      setExtendedSlides([...extendedSlides, ...slides]);
    }
    // Check if we are near the end and duplicate slides if needed
    if (scrollWidth - (scrollLeft + clientWidth) < clientWidth / 2) {
      // Logic to dynamically add slides could go here
      // For simplicity, assuming slides are duplicated beforehand
      
    }
    
    // Auto scroll logic
    if (autoplay && !isHovering) {
      sliderRef.current.scrollLeft += 1;
    }
  };
  

  useEffect(() => {
    if (autoplay) {
      requestRef.current = requestAnimationFrame(handleScroll);
    }
    return () => cancelAnimationFrame(requestRef.current);
  },[timestamp]);

  // set timestamp every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(Date.now());
    }, 10);
    return () => clearInterval(interval);
  }, []);

  // Adjust className based on autoplay and isHovering state
  const sliderClassName = `${className} 'autoplay'`;

  return (
    <div
      className={sliderClassName}
      ref={sliderRef}
    >
      <div className="slider-animation-container">
        {extendedSlides.map((slide, index) => (
          <SliderCard
            key={index}
            image={slide.image}
            title={slide.title}
            className="slider-card"
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
