import React from 'react';
import Button from '../atoms/Button';
import './Header.css';

const Header = ({hasCallToAction, isSmallHeader}) => {
  return (
    <div className={`header ${isSmallHeader ? 'small-header' : ''}`}>
      <div className="logo">
        <img src="/assets/logo.svg" alt="Logo" />
      </div>
      {hasCallToAction && 
      <Button text="Join the waitlist" radius={30} bgcolor={"#00C8F5"} textcolor={"#ffffff"} onClick={() => {
        //  scroll down to #cta
          const hero = document.getElementById('cta');
          if (hero) {
            hero.scrollIntoView({ behavior: 'smooth' });
          }
        }}/>
      }
    </div>
  );
};

export default Header;
