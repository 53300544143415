import React, { useRef, useEffect } from 'react';
import Avatar from '../atoms/Avatar';
import './CircleOfAvatars.css';

const CircleOfAvatars = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleMouseMove = (event) => {
      const { left, top, width, height } = container.getBoundingClientRect();
      const centerX = left + width / 2;
      const centerY = top + height / 2;

      Array.from(container.children).forEach((child) => {
        if (child.id.startsWith('avatar')) {
          const childRect = child.getBoundingClientRect();
          const childCenterX = childRect.left + childRect.width / 2;
          const childCenterY = childRect.top + childRect.height / 2;

          const directionX = event.clientX - childCenterX;
          const directionY = event.clientY - childCenterY;
          const distance = Math.sqrt(directionX * directionX + directionY * directionY);
          const normalizedX = directionX / distance;
          const normalizedY = directionY / distance;
          const moveDistance = 10;
          child.style.transform = `translate(${-normalizedX * moveDistance}px, ${-normalizedY * moveDistance}px)`;
        }
      });
    };

    container.addEventListener('mousemove', handleMouseMove);

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div ref={containerRef} className="circle-of-avatars" style={{ position: 'relative', width: '582px', height: '537px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', borderRadius: '50%' }}>
      <img src='/assets/people/webrings.png' className='webrings' alt='Background of map of people' />
      <div id={`avatar-1`} style={{ position: 'absolute' }}>
        <Avatar image={"/assets/people/1.png"} size="105px" flag="ghana" name="Kwadwo" additionalInfo="solo traveler" />
      </div>
      <div id={`avatar-2`} style={{ position: 'absolute' }}>
        <Avatar image={"/assets/people/2.png"} size="80px" flag='rwanda' name="Hana" additionalInfo="digital nomad" />
      </div>
      <div id={`avatar-3`} style={{ position: 'absolute' }}>
        <Avatar image={"/assets/people/3.png"} size="98px" flag='ghana' name="Akua" additionalInfo="solo traveler" />
      </div>
      <div id={`avatar-center`} style={{ position: 'absolute' }}>
        <Avatar image={"/assets/people/4.png"} size="115px" flag='ivory-coast' name="Sharon" additionalInfo="foodie" />
      </div>
      <div id={`avatar-4`} style={{ position: 'absolute' }}>
        <Avatar image={"/assets/people/5.png"} size="90px" flag="us" name="Brandon" additionalInfo="Eco-tourist" />
      </div>
    </div>
  );
};

export default CircleOfAvatars;
