import React from 'react';

const Avatar = ({ image, size, round = true, flag="", name, additionalInfo }) => {
  const avatarStyle = {
    width: size,
    height: size,
    borderRadius: round ? '50%' : '0%',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'inline-block',
    position: 'relative', // Needed to position the floating div correctly
  };

  // Floating div style
  const floatingDivStyle = {
    backgroundColor: 'white',
    borderRadius: '52px',
    padding: '5px 10px',
    position: 'absolute',
    bottom: '-3%', // Position at the top of the avatar
    left: '-9%',
    transform: 'translateX(-50%)', // Center horizontally
    whiteSpace: 'nowrap', // Prevent text wrapping
    marginBottom: '5px', // Gap between the floating div and avatar
    fontSize: '0.8em', // Smaller font size for the floating info
    zIndex: 1
  };

  return (
    <div style={{ display: 'inline-block', position: 'relative' }}> {/* Wrapper div to control the overall positioning */}
      {name && additionalInfo && (
        <div className='floatingdiv' style={floatingDivStyle}>
          <span className='flag'><img src={"/assets/flags/"+flag+".png"} alt={`flag of ${flag}`} /></span><span className="floating_name">{name},</span> <span className="floating_info">{additionalInfo}</span>
        </div>
      )}
      <div className='avatardiv' style={avatarStyle} />
    </div>
  );
};

export default Avatar;
