import React, { useState, useEffect } from 'react';
import './SignUpForm.css'; // This imports the CSS from a separate file
import Button from '../atoms/Button';

const SignUpForm = ({page_a_or_b}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [joinbeta, setJoinbeta] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isloading, setIsLoading] = useState(false);

  const validateEmail = (email) => {
    // Simple regex for basic email validation
    return /\S+@\S+\.\S+/.test(email);
  };

  const validateForm = () => {
    return name.length > 0 && validateEmail(email);
  };

  useEffect(() => {
    // Enable or disable button based on form validation
    setIsButtonDisabled(!validateForm());
  }, [name, email, page_a_or_b, joinbeta]); // This effect depends on name and email state

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (!validateForm()) return; // Ensure the form is valid before submitting
  
    const formData = {
      name: name,
      email: email,
      page_a_or_b: page_a_or_b,
      referral: localStorage.getItem('referral'),
      joinbeta: joinbeta
    };
  
    try {
      const response = await fetch('https://api.traaple.com/admin/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();
      // Handle success
      console.log(result);
      setSubmitted(true);
      setIsLoading(false);
    } catch (error) {
      // Handle error
      console.error('Error:', error);
      setIsLoading(false);
    }
  };
  

  return (
    <div className="signup-form">
      <h2 className={submitted ? 'fade': ''}>Join the Traaple waitlist today</h2>
      <form onSubmit={handleSubmit} className={submitted ? 'fade': ''}>
        <div className="input-group">
          <label htmlFor="name">Name</label>
          <input 
            type="text" 
            id="name" 
            placeholder="John Doe" 
            required 
            value={name}
            onChange={(e) => setName(e.target.value)} // Update state on change
          />
        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            id="email" 
            placeholder="name@email.com" 
            required 
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update state on change
          />
        </div>
        {/* checkbox for Sign me up to test early versions of Traaple. */}
        <div className="input-group" style={{display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
          <input type="checkbox" id="isbeta" style={{width: 'auto'}} onChange={(e) => setJoinbeta(e.target.checked)} />
          <label htmlFor="checkbox" style={{marginBottom: '0', color: '#0F2A47', marginLeft: '5px'}}>Sign me up to test early versions of Traaple</label> 
        </div>
        <div className='submit-button-container'>
        {!submitted &&<Button 
          text="Join waitlist" 
          radius={30} 
          bgcolor={"#0F2A47"} 
          textcolor={"#4FC3F7"}
          onClick={() => {}}
          size="full" 
          disabled={isButtonDisabled} // Use state to control disabled property
          isloading={isloading}
        />}
        </div>
      </form>

      {submitted && <div className='submission-note'><img src="../../assets/signedup.png" alt="Success" />
      <h4>You’ve been added to our waitlist</h4>
      <p>Your email <span>{email}</span> has been added to our waitlist. We’ll let you know when Traaple is ready.</p></div>}
    </div>
  );
};

export default SignUpForm;
