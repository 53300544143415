import React from 'react';
import Button from '../atoms/Button';
import './Jumbotron.css';

// Utility function to convert strings with <br> tags into React elements
const convertStringToJSX = (text) => {
  const parts = text.split(/<br\s*\/?>/i); // Split the string by <br> and <br/> tags, case-insensitive
  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part}
      {index < parts.length - 1 && <br />} {/* Add a <br> element for all but the last part */}
    </React.Fragment>
  ));
};

const Jumbotron = ({ onAction, title, subtitle, button, imageOnRight, imageUrl, hasVideo }) => {
  return (
    <div>
      {hasVideo && (
        <div className={`jumbotron with-video-bg ${imageOnRight ? 'image-on-right' : ''}`}>
          <video className='intro-video' autoPlay loop muted>
            <source src="https://bucket.traaple.com/homepage/intro.mp4" type="video/mp4" />
          </video>
          <div className='overlay'></div>
          <div className="content">
            <div className="text">
              <h1>{convertStringToJSX(title)}</h1>
              <p>{convertStringToJSX(subtitle)}</p>
              <Button onClick={onAction} text={button} radius={30} bgcolor={"#4FC3F7"} textcolor={"#0F2A47"} />
            </div>
          </div>
        </div>
      )}

      {!hasVideo && (
        <div className={`jumbotron no-video-bg ${imageOnRight ? 'image-on-right' : ''}`}>
          <div className="content">
            <div className="text">
              <h1>{convertStringToJSX(title)}</h1>
              <p>{convertStringToJSX(subtitle)}</p>
              <Button onClick={onAction} text={button} radius={30} bgcolor={"#4FC3F7"} textcolor={"#0F2A47"} />
            </div>
            {/* {imageOnRight && <img src={imageUrl} alt="Jumbotron" />} */}
          </div>
          {/* {!imageOnRight && <img src={imageUrl} alt="Jumbotron" />} */}
        </div>
      )}
    </div>
  );
};

export default Jumbotron;
