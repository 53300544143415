import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/organisms/Header';
import Jumbotron from './components/organisms/Jumbotron';
import Card from './components/organisms/Card';
import CallToAction from './components/organisms/CallToAction';
import Footer from './components/organisms/Footer';
import Carousel from './components/organisms/Carousel';
import PrivacyPolicy from './PrivacyPolicy';
import RequestAccountDeletion from './RequestAccountDeletion';

import { useInView } from 'react-intersection-observer';

function App() {
  const slides = [
    { image: "/assets/home-slide/1.jpg", title: "Wildlife Safaris" },
    { image: "/assets/home-slide/2.jpg", title: "Sightseeing at Landmarks" },
    { image: "/assets/home-slide/3.jpg", title: "Bird watching" },
    { image: "/assets/home-slide/4.jpg", title: "Paragliding" },
    { image: "/assets/home-slide/5.jpg", title: "Waterfalls" },
    { image: "/assets/home-slide/6.jpg", title: "Relaxing on Beaches" },
    { image: "/assets/home-slide/7.jpg", title: "Traditional Dance Shows" },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get('ref');

  if (ref) {
    const referral = ref;
    localStorage.setItem('referral', referral);
  }

  const [a_or_b, setAorB] = useState("a");

  useEffect(() => {
    setAorB(Math.random() < 0.5 ? "a" : "b");
  }, []);

  return (
    <Router>
      <div className="App">
        <Header hasCallToAction={a_or_b === "b"} isSmallHeader={a_or_b === "b"} />
       

        <Routes>
          <Route path="/" element={
            <>
             {a_or_b === "a" && <img className='home-trail' src={'../assets/trail.svg?timestamp=' + new Date().getTime()} alt="Trail Path" />}
              <section className={`section animate ${a_or_b === "b" ? "full-width" : ""}`}>
                <Jumbotron
                  title="Discover and book things <br>to do across Africa."
                  subtitle="We use local insights and advanced data science to <br>provide you with immersive travel experiences."
                  imageUrl="../assets/trail.svg"
                  hasVideo={a_or_b === "b"}
                  button="Join the waitlist"
                  onAction={() => {
                    const hero = document.getElementById('cta');
                    if (hero) {
                      hero.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                />
              </section>

              <div className='hero-carousel animate'>
                {a_or_b === "a" &&
                  <Carousel slides={slides} autoScroll={true} speed={1000} autoScrollSpeed={500} slidesToScroll={5} slidesToShow={3.4} card_image_height='386px' cardTitleSwapping={false} responsive={[
                    {
                      breakpoint: 1150,
                      settings: {
                        slidesToShow: 3,
                        centerPadding: '20px',
                      }
                    },
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 2.5,
                        centerPadding: '50px',
                      }
                    },
                    {
                      breakpoint: 800,
                      settings: {
                        slidesToShow: 2,
                        centerPadding: '20px',
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1.2,
                        centerPadding: '40px',
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        centerPadding: '30px',
                      }
                    }
                  ]} />
                }
              </div>

              <section className="section">
                <p className="value-prop">
                  Limited choices, complex bookings, hidden fees, and unreliable <br />information are now history. <span>Traaple — your direct access to Africa's <br />most vibrant travel experiences.</span>
                </p>
              </section>

              <section className="section mb-140">
                <div className='card-grid'>
                  <div className='card-col'>
                    <div className="card-container curated">
                      <Card title="Curated travel experiences" subtitle="We want your journey across Africa to be filled with memorable moments. Traaple's curated travel experiences aim to provide a one-of-a-kind journey for you - tailored to your taste, budget, and preferences." image="../assets/curated.gif" imagePosition="top" borderRadius="20" />
                    </div>

                    <div className="card-container svgcard">
                      <Card title="A Network of Trusted Providers" subtitle="Traaple has built a vast network of quality and reliable travel service providers across Africa, giving you access to a wide array of options - from activities and attractions to guided tours and exclusive events." image="../assets/iconCircle.svg" image_type='iconcircle' imagePosition="top" borderRadius="20" />
                    </div>

                    <div className="card-container">
                      <Card title="Traveller safety & security" subtitle="To truly enjoy your travel experience, you need peace of mind, knowing that your travel investments are protected in unforeseen circumstances. Traaple prioritizes your well-being by providing comprehensive insurance coverage, so you can have a secure and worry-free travel experience." image="../assets/travellers.png" imagePosition="top" borderRadius="20" />
                    </div>
                  </div>

                  <div className='card-col'>
                    <div className="card-container mobilecard">
                      <Card title="Personalised recommendations" subtitle="Say goodbye to blind searches and hello to a travel plan uniquely crafted for you.  Our cutting-edge personalization algorithms eliminate the guesswork. By analyzing your preferences, past experiences, and stated interests, to give you relevant and appealing options." image="../assets/personalized.gif" imagePosition="top" borderRadius="20" />
                    </div>

                    <div className="card-container circleofavatars">
                      <Card title="Community of travellers" subtitle="Unlock a world of shared experiences and insights. Connect with like-minded travelers discover travel tips and hacks, and create bonds through forums and interactive features." image="../assets/community.png" image_type="circleofavatars" imagePosition="top" borderRadius="20" />
                    </div>

                    <div className="card-container">
                      <Card title="First-class experiences" subtitle="Need a concierge dedicated to you around the clock? Traaple Premium caters to all your high-end travel needs. From travel process aid to transportation, accommodation to personal chefs and butlers, private jet charters, yachts, exclusive access to hidden gems and other luxurious experiences." image="../assets/premium.png" imagePosition="top" borderRadius="20" />
                    </div>
                  </div>
                </div>
              </section>

              <span id="cta"></span>
              <CallToAction
                backgroundImage="../assets/forest.png"
                title={a_or_b === "a" ? "" : "Embark on your journey"}
                text={a_or_b === "a" ? "Find things to do, connect with other travelers and immerse yourself in Africa’s diverse destinations" : "Join Traaple now for tailored adventures. Tailor made experiences, seamless planning, and meaningful connections await."}
                buttonText={a_or_b === "a" ? "Join the waitlist" : "Sign up now!"}
                buttonBackgroundColor={a_or_b === "a" ? "#00C8F5" : "#0F2A47"}
                buttonTextColor={a_or_b === "a" ? "#000C16" : "#ffffff"}
                isSpaced={a_or_b === "a" ? false : true}
                page_a_or_b={a_or_b}
              />
            </>
          } />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/delete-account" element={<RequestAccountDeletion />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
