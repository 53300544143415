import React from 'react';
import Button from '../atoms/Button';
import './CallToAction.css';
import SignUpForm from './SignUpForm';
import { useInView } from 'react-intersection-observer';

const CallToAction = ({ backgroundImage, backgroundColor, title, text, buttonText, buttonBackgroundColor, buttonTextColor, isSpaced, page_a_or_b }) => {

  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.1, 
  });

  const backgroundStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
    backgroundColor: backgroundColor ? backgroundColor : 'transparent',
  };

  return (
    <div className={`call-to-action ${isSpaced ? 'spaced' : ''}`} style={backgroundStyle}>
      <div className={`cta-content ${inView ? 'animate' : ''}`} ref={ref}>
      <SignUpForm page_a_or_b={page_a_or_b} />
      </div>
    </div>
  );
};

export default CallToAction;
