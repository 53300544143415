// SliderCard.js
import React from 'react';
import './SliderCard.css';

const SliderCard = ({ image, title, is_center = false, borderRadius = '5px', boxShadow = '0px 0px 0px rgba(0, 0, 0, 0)', image_height='320px', padding="0", titleSwapping=false }) => {
  // Use camelCase for the property name
  let boxShadowStyle = "0px 0px 0px rgba(0, 0, 0, 0)"; // Default shadow
  if (is_center) {
    boxShadowStyle = boxShadow; // Apply specified shadow if is_center is true
  }
  return (
    <div className={`slider-card ${is_center ? 'slider-card-center' : ''}`} style={{ borderRadius, boxShadow: boxShadowStyle, padding }}>
      <img src={image} alt={title} className="slider-card-image" style={{height: image_height, objectFit: 'cover'}} />
      {titleSwapping ? (is_center ? <h3 className="slider-card-title funky">{title}</h3> : <h3>&nbsp;</h3>) : null}

      {!titleSwapping && <h3 className="slider-card-title normal">{title}</h3>}
    </div>
  );
};

export default SliderCard;
