import React, { useEffect, useState } from 'react';
import Button from '../atoms/Button';
import './Footer.css'; // Import CSS for styling

const Footer = () => {
  const [year, setYear] = useState(0);

  useEffect(() => {
    // Current year
    setYear(new Date().getFullYear());
  })
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/assets/logow.svg" alt="Logo" />
        </div>
        <div className="social-media-buttons">
          <Button radius={30} bgcolor="#ffffff" textcolor="#ffffff" text="Twitter" onClick={() => {
            window.open('https://twitter.com/traaplecom', '_blank');
          }} buttonType="outline" />
          <Button radius={30} bgcolor="#ffffff" textcolor="#ffffff" text="Instagram" onClick={() => { 
            window.open('https://instagram.com/traaplecom', '_blank');
          }} buttonType="outline" />
          <Button radius={30} bgcolor="#ffffff" textcolor="#ffffff" text="LinkedIn" onClick={() => {
            window.open('https://www.linkedin.com/company/traaplecom', '_blank');
          }} buttonType="outline" />
          <Button radius={30} bgcolor="#ffffff" textcolor="#ffffff" text="Facebook" onClick={() => {
            window.open('https://www.facebook.com/traaplecom', '_blank');
          }} buttonType="outline" />
        </div>
      </div>
      <div className="footer-line"></div>
      <div className="copyright">
        © {year} Traaple.
      </div>
    </div>
  );
};

export default Footer;
