import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy" style={{ textAlign: 'left', padding: '40px' }}>
            <h1>Privacy Policy</h1>
            <p>Last updated:&nbsp;</p>
            <p>This Privacy Policy outlines the information Traaple Inc ("we," "our," or "us") may collect, use, and disclose when you use our website/application/service (the "Service").</p>
            <ol>
                <li>
                    <ul>
                        <li>
                            <h4>WHAT INFORMATION DO WE COLLECT?</h4>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h4>Personal information you disclose to us.</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <h4>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services when you participate in activities on the Services, or otherwise when you contact us.</h4>
            <p>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
            <ul>
                <li>
                    <p>Names</p>
                </li>
                <li>
                    <p>Phone Numbers</p>
                </li>
                <li>
                    <p>Email Addresses</p>
                </li>
                <li>
                    <p>Mailing Addresses</p>
                </li>
                <li>
                    <p>Job Titles</p>
                </li>
                <li>
                    <p>Passwords</p>
                </li>
                <li>
                    <p>Usernames</p>
                </li>
                <li>
                    <p>Contact Preferences</p>
                </li>
                <li>
                    <p>Contact or Authentication Data</p>
                </li>
                <li>
                    <p>Billing Addresses</p>
                </li>
            </ul>
            <ol start="2">
                <li>
                    <ul>
                        <li>
                            <p>Sensitive Information.&nbsp;</p>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We do not process sensitive information.</p>
            <ol start="3">
                <li>
                    <ul>
                        <li>
                            <p>Payment Data</p>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here:&nbsp;<a href="https://stripe.com/privacy"><u>https://stripe.com/privacy</u></a>.</p>
            <ol start="4">
                <li>
                    <ul>
                        <li>
                            <p>Social Media Login Data</p>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We may provide you with the option to register with us using your existing social media accounts details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.</p>
            <ol start="5">
                <li>
                    <ul>
                        <li>
                            <p>Application Data</p>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
            <ul>
                <li>
                    <p><strong>Geolocation Information -&nbsp;</strong>We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.</p>
                </li>
                <li>
                    <p><strong>Mobile Device Access -&nbsp;</strong>We may request access or permission to certain features from your mobile device, including your mobile device's contacts, microphone, SMS messages, camera, storage, reminders, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</p>
                </li>
                <li>
                    <p><strong>Mobile Device Data -&nbsp;</strong>&nbsp;We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device&rsquo;s operating system or platform, the type of mobile device you use, your mobile device&rsquo;s unique device ID, and information about the features of our application(s) you accessed.</p>
                </li>
                <li>
                    <p><strong>Push Notifications -&nbsp;</strong>We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</p>
                </li>
            </ul>
            <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
            <ol start="6">
                <li>
                    <ul>
                        <li>
                            <p>Information automatically collected</p>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes. Like many businesses, we also collect information through cookies and similar technologies.</p>
            <ol start="7">
                <li>
                    <ul>
                        <li>
                            <p>The information we collect includes:</p>
                        </li>
                    </ul>
                </li>
            </ol>
            <ul>
                <li>
                    <p><strong>Log and Usage Data -&nbsp;</strong>Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</p>
                </li>
                <li>
                    <p><strong>Device Data -&nbsp;</strong>&nbsp;We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</p>
                </li>
                <li>
                    <p><strong>Location Data -&nbsp;</strong>&nbsp;We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</p>
                </li>
            </ul>
            <ol start="2">
                <li>
                    <ul>
                        <li>
                            <h4>HOW DO WE PROCESS YOUR INFORMATION?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
            <ul>
                <li>
                    <p>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</p>
                </li>
                <li>
                    <p>To deliver and facilitate the delivery of services to the user. We may process your information to provide you with the requested service.</p>
                </li>
                <li>
                    <p>To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</p>
                </li>
                <li>
                    <p>To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</p>
                </li>
                <li>
                    <p>To fulfill and manage your orders. We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</p>
                </li>
                <li>
                    <p>To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.</p>
                </li>
                <li>
                    <p>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</p>
                </li>
                <li>
                    <p>To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below.</p>
                </li>
                <li>
                    <p>To deliver targeted advertising to you. We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</p>
                </li>
                <li>
                    <p>To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</p>
                </li>
                <li>
                    <p>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</p>
                </li>
                <li>
                    <p>To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</p>
                </li>
                <li>
                    <p>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm.</p>
                </li>
            </ul>
            <ol start="3">
                <li>
                    <ul>
                        <li>
                            <h4>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p><u><em>If you are located in the EU or UK, this section applies to you.&nbsp;</em></u></p>
            <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
            <ul>
                <li>
                    <p>Consent. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.</p>
                </li>
                <li>
                    <p>Performance of a Contract. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</p>
                </li>
                <li>
                    <p>Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</p>
                </li>
                <li>
                    <p>Send users information about special offers and discounts on our products and services</p>
                </li>
                <li>
                    <p>Develop and display personalized and relevant advertising content for our users</p>
                </li>
                <li>
                    <p>Analyze how our Services are used so we can improve them to engage and retain users</p>
                </li>
                <li>
                    <p>Support our marketing activities</p>
                </li>
                <li>
                    <p>Diagnose problems and/or prevent fraudulent activities</p>
                </li>
                <li>
                    <p>Understand how our users use our products and services so we can improve user experience</p>
                </li>
                <li>
                    <p>Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</p>
                </li>
                <li>
                    <p>Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as in situations involving potential threats to the safety of any person.</p>
                </li>
            </ul>
            <p><u><em>If you are located in Canada, this section applies to you.</em></u></p>
            <p>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.</p>
            <p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>
            <ul>
                <li>
                    <p>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</p>
                </li>
                <li>
                    <p>For investigations, fraud detection and prevention</p>
                </li>
                <li>
                    <p>For business transactions provided certain conditions are met</p>
                </li>
                <li>
                    <p>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</p>
                </li>
                <li>
                    <p>For identifying injured, ill, or deceased persons and communicating with next of kin</p>
                </li>
                <li>
                    <p>If we have reasonable grounds to believe an individual has been, is, or may be a victim of financial abuse</p>
                </li>
                <li>
                    <p>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</p>
                </li>
                <li>
                    <p>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</p>
                </li>
                <li>
                    <p>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</p>
                </li>
                <li>
                    <p>If the collection is solely for journalistic, artistic, or literary purposes</p>
                </li>
                <li>
                    <p>If the information is publicly available and is specified by the regulations</p>
                </li>
            </ul>
            <ol start="4">
                <li>
                    <ul>
                        <li>
                            <h4>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do that work. The categories of third parties we may share personal information with are as follows:</p>
            <ul>
                <li>
                    <p>Cloud Computing Services</p>
                </li>
                <li>
                    <p>Data Analytics Services</p>
                </li>
                <li>
                    <p>Data Storage Service Providers</p>
                </li>
                <li>
                    <p>Finance &amp; Accounting Tools</p>
                </li>
                <li>
                    <p>User Account Registration &amp; Authentication Services</p>
                </li>
                <li>
                    <p>Payment Processors</p>
                </li>
                <li>
                    <p>Order Fulfillment Service Providers</p>
                </li>
            </ul>
            <p>We also may need to share your personal information in the following situations:</p>
            <ul>
                <li>
                    <p>Business Transfers. We may share or transfer your information in connection with or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
                </li>
                <li>
                    <p>When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). We obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</p>
                </li>
                <li>
                    <p>Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</p>
                </li>
                <li>
                    <p>Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.</p>
                </li>
                <li>
                    <p>Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</p>
                </li>
            </ul>
            <ol start="5">
                <li>
                    <ul>
                        <li>
                            <h4>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
            <ol start="6">
                <li>
                    <ul>
                        <li>
                            <h4>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</p>
            <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
            <ol start="7">
                <li>
                    <ul>
                        <li>
                            <h4>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>Our servers are located in the United States. If you are accessing our Services from outside the United States, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in the United States, and other countries.</p>
            <p>If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>
            <ol start="8">
                <li>
                    <ul>
                        <li>
                            <h4>HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).&nbsp;No purpose in this notice will require us to keep your personal information for longer than the period of time in which users have an account with us.</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            <ol start="9">
                <li>
                    <ul>
                        <li>
                            <h4>HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
            <ol start="10">
                <li>
                    <ul>
                        <li>
                            <h4>DO WE COLLECT INFORMATION FROM MINORS?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under the age of 18, please contact us at&nbsp;<a href="mailto:privacy@traaple.com"><u>hi@traaple.com</u></a>.</p>
            <ol start="11">
                <li>
                    <ul>
                        <li>
                            <h4>WHAT ARE YOUR PRIVACY RIGHTS?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right&nbsp;</p>
            <ol>
                <li>
                    <p>to request access and obtain a copy of your personal information,&nbsp;</p>
                </li>
                <li>
                    <p>to request rectification or erasure;&nbsp;</p>
                </li>
                <li>
                    <p>to restrict the processing of your personal information;&nbsp;</p>
                </li>
                <li>
                    <p>if applicable, to data portability; and&nbsp;</p>
                </li>
                <li>
                    <p>not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information.</p>
                </li>
            </ol>
            <p>You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>
            <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
            <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your&nbsp;<a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"><u>Member State data protection authority</u></a>&nbsp;or&nbsp;<a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"><u>UK data protection authority</u></a>.</p>
            <p>If you are located in Switzerland, you may contact the&nbsp;<a href="https://www.edoeb.admin.ch/edoeb/en/home.html"><u>Federal Data Protection and Information Commissioner</u></a>.</p>
            <p><u>Withdrawing your consent:</u>&nbsp;If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below or by updating your preferences.</p>
            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
            <p><u>Opting out of marketing and promotional communications:&nbsp;</u>You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still communicate with you &mdash; for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
            <h5><strong>Account Information:</strong></h5>
            <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
            <ul>
                <li>
                    <p>Log in to your account settings and update your user account.</p>
                </li>
            </ul>
            <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms, and/or comply with applicable legal requirements.</p>
            <p><u>Cookies and similar technologies:</u>&nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.</p>
            <p>If you have questions or comments about your privacy rights, you may email us at hi@traaple.com.</p>
            <ol start="12">
                <li>
                    <ul>
                        <li>
                            <h4>CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
            <ol start="13">
                <li>
                    <ul>
                        <li>
                            <h4>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>What categories of personal information do we collect?</p>
            <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>Category</p>
                            </td>
                            <td>
                                <p>Examples</p>
                            </td>
                            <td>
                                <p>Collected</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>A. Identifiers</p>
                            </td>
                            <td>
                                <p>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</p>
                            </td>
                            <td>
                                <p>YES</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>B. Personal information as defined in the California Customer Records statute</p>
                            </td>
                            <td>
                                <p>Name, contact information, education, employment, employment history, and financial information</p>
                            </td>
                            <td>
                                <p>YES</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>C. Protected classification characteristics under state or federal law</p>
                            </td>
                            <td>
                                <p>Gender and date of birth</p>
                            </td>
                            <td>
                                <p>NO</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>D. Commercial information</p>
                            </td>
                            <td>
                                <p>Transaction information, purchase history, financial details, and payment information</p>
                            </td>
                            <td>
                                <p>YES</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>E. Biometric information</p>
                            </td>
                            <td>
                                <p>Fingerprints and voiceprints</p>
                            </td>
                            <td>
                                <p>NO</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>F. Internet or other similar network activity</p>
                            </td>
                            <td>
                                <p>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</p>
                            </td>
                            <td>
                                <p>YES</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>G. Geolocation data</p>
                            </td>
                            <td>
                                <p>Device location</p>
                            </td>
                            <td>
                                <p>YES</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>H. Audio, electronic, visual, thermal, olfactory, or similar information</p>
                            </td>
                            <td>
                                <p>Images and audio, video or call recordings created in connection with our business activities</p>
                            </td>
                            <td>
                                <p>NO</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>I. Professional or employment-related information</p>
                            </td>
                            <td>
                                <p>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</p>
                            </td>
                            <td>
                                <p>NO</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>J. Education Information</p>
                            </td>
                            <td>
                                <p>Student records and directory information</p>
                            </td>
                            <td>
                                <p>NO</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>K. Inferences are drawn from collected personal information</p>
                            </td>
                            <td>
                                <p>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics</p>
                            </td>
                            <td>
                                <p>YES</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>L. Sensitive Personal Information</p>
                            </td>
                            <td>
                                <p>NO</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
            <ul>
                <li>
                    <p>Category A - As long as the user has an account with us</p>
                </li>
                <li>
                    <p>Category B - As long as the user has an account with us</p>
                </li>
                <li>
                    <p>Category D - As long as the user has an account with us</p>
                </li>
                <li>
                    <p>Category F - As long as the user has an account with us</p>
                </li>
                <li>
                    <p>Category G - As long as the user has an account with us</p>
                </li>
                <li>
                    <p>Category K - As long as the user has an account with us</p>
                </li>
            </ul>
            <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
            <ul>
                <li>
                    <p>Receiving help through our customer support channels;</p>
                </li>
                <li>
                    <p>Participation in customer surveys or contests; and</p>
                </li>
                <li>
                    <p>Facilitation in the delivery of our Services and to respond to your inquiries.</p>
                </li>
            </ul>
            <h5><strong>How do we use and share your personal information?</strong></h5>
            <p>Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"</p>
            <p>We collect and share your personal information through:</p>
            <ul>
                <li>
                    <p>Targeting cookies/Marketing cookies</p>
                </li>
                <li>
                    <p>Social media cookies</p>
                </li>
                <li>
                    <p>Beacons/Pixels/Tags</p>
                </li>
            </ul>
            <h5><strong>Will your information be shared with anyone else?</strong></h5>
            <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information in the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</p>
            <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" your personal information.</p>
            <p>We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:</p>
            <p>The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</p>
            <p>We have sold or shared the following categories of personal information to third parties in the preceding twelve (12) months:</p>
            <p>The categories of third parties to whom we sold personal information are:</p>
            <p>The categories of third parties to whom we shared personal information with are:</p>
            <ul>
                <li>
                    <p>User Account Registration &amp; Authentication Services</p>
                </li>
                <li>
                    <p>Data Analytics Services</p>
                </li>
            </ul>
            <h5><strong>California Residents</strong></h5>
            <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
            <p>If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request the removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</p>
            <h6><u>CCPA Privacy Notice</u></h6>
            <p>This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.</p>
            <p>The California Code of Regulations defines a "resident" as:</p>
            <p>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</p>
            <p>(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</p>
            <p>All other individuals are defined as "non-residents."</p>
            <p>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>
            <p>Your rights with respect to your personal data</p>
            <h6><u>Right to request deletion of the data &mdash;&nbsp;</u><u>Request to delete</u></h6>
            <p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</p>
            <h6><u>Right to be informed &mdash; Request to know</u></h6>
            <p>Depending on the circumstances, you have a right to know:</p>
            <ul>
                <li>
                    <p>whether we collect and use your personal information;</p>
                </li>
                <li>
                    <p>the categories of personal information that we collect;</p>
                </li>
                <li>
                    <p>the purposes for which the collected personal information is used;</p>
                </li>
                <li>
                    <p>whether we sell or share personal information to third parties;</p>
                </li>
                <li>
                    <p>the categories of personal information that we sold, shared, or disclosed for a business purpose;</p>
                </li>
                <li>
                    <p>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</p>
                </li>
                <li>
                    <p>the business or commercial purpose for collecting, selling, or sharing personal information; and</p>
                </li>
                <li>
                    <p>the specific pieces of personal information we collected about you.</p>
                </li>
            </ul>
            <p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>
            <h6><u>Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s Privacy Rights</u></h6>
            <p>We will not discriminate against you if you exercise your privacy rights.</p>
            <h6><u>Right to Limit Use and Disclosure of Sensitive Personal Information</u></h6>
            <p>We do not process consumer's sensitive personal information.</p>
            <h6><u>Verification process</u></h6>
            <p>Upon receiving your request, we will need to verify your identity to determine you are the same person with whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with the information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</p>
            <p>We will only use the personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additional information as soon as we finish verifying you.</p>
            <h6><u>Other privacy rights</u></h6>
            <ul>
                <li>
                    <p>You may object to the processing of your personal information.</p>
                </li>
                <li>
                    <p>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</p>
                </li>
                <li>
                    <p>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</p>
                </li>
            </ul>
            <p>You can opt-out from the selling or sharing of your personal information by disabling cookies in Cookie Preference Settings and clicking on the Do Not Sell or Share My Personal Information link on our homepage.</p>
            <p>To exercise these rights, you can contact us by visiting&nbsp;<a href="https://getodeen.com/privacy/data-request"><u>https://traaple.com/privacy/data-request</u></a>, by calling toll-free at +1-855-946-3336, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</p>
            <h5><strong>Colorado Residents</strong></h5>
            <p>This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</p>
            <ul>
                <li>
                    <p>Right to be informed whether or not we are processing your personal data</p>
                </li>
                <li>
                    <p>Right to access your personal data</p>
                </li>
                <li>
                    <p>Right to correct inaccuracies in your personal data</p>
                </li>
                <li>
                    <p>Right to request deletion of your personal data</p>
                </li>
                <li>
                    <p>Right to obtain a copy of the personal data you previously shared with us</p>
                </li>
                <li>
                    <p>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</p>
                </li>
            </ul>
            <p>We sell personal data to third parties or process personal data for targeted advertising. You can opt-out from the selling of your personal data, targeted advertising, or profiling by disabling cookies in Cookie Preference Settings. To submit a request to exercise any of the other rights described above, please email hi@traaple.com or visit&nbsp;<a href="https://getodeen.com/privacy/data-request"><u>https://traaple.com/privacy/data-request</u></a>.</p>
            <p>If we decline to take action regarding your request and you wish to appeal our decision, please email us at hi@traaple.com. Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.</p>
            <h5><strong>Connecticut Residents</strong></h5>
            <p>This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</p>
            <ul>
                <li>
                    <p>Right to be informed whether or not we are processing your personal data</p>
                </li>
                <li>
                    <p>Right to access your personal data</p>
                </li>
                <li>
                    <p>Right to correct inaccuracies in your personal data</p>
                </li>
                <li>
                    <p>Right to request deletion of your personal data</p>
                </li>
                <li>
                    <p>Right to obtain a copy of the personal data you previously shared with us</p>
                </li>
                <li>
                    <p>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</p>
                </li>
            </ul>
            <p>We sell personal data to third parties or process personal data for targeted advertising. You can opt-out from the selling of your personal data, targeted advertising, or profiling by disabling cookies in Cookie Preference Settings. To submit a request to exercise any of the other rights described above, please email hi@traaple.com or visit&nbsp;<a href="https://getodeen.com/privacy/data-request"><u>https://traaple.com/privacy/data-request</u></a>.</p>
            <p>If we decline to take action regarding your request and you wish to appeal our decision, please email us at hi@traaple.com. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.</p>
            <p>Utah Residents</p>
            <p>This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</p>
            <ul>
                <li>
                    <p>Right to be informed whether or not we are processing your personal data</p>
                </li>
                <li>
                    <p>Right to access your personal data</p>
                </li>
                <li>
                    <p>Right to request deletion of your personal data</p>
                </li>
                <li>
                    <p>Right to obtain a copy of the personal data you previously shared with us</p>
                </li>
                <li>
                    <p>Right to opt out of the processing of your personal data if it is used for targeted advertising or the sale of personal data</p>
                </li>
            </ul>
            <p>We sell personal data to third parties or process personal data for targeted advertising. You can opt-out from the selling of your personal data or targeted advertising by disabling cookies in Cookie Preference Settings. To submit a request to exercise any of the other rights described above, please email hi@traaple.com or visit&nbsp;<a href="https://getodeen.com/privacy/data-request"><u>https://traaple.com/privacy/data-request</u></a>.</p>
            <h5><strong>Virginia Residents</strong></h5>
            <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>
            <p>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>
            <p>"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.</p>
            <p>"Sale of personal data" means the exchange of personal data for monetary consideration.</p>
            <p>If this definition of "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.</p>
            <h6><u>Your rights with respect to your personal data</u></h6>
            <ul>
                <li>
                    <p>Right to be informed whether or not we are processing your personal data</p>
                </li>
                <li>
                    <p>Right to access your personal data</p>
                </li>
                <li>
                    <p>Right to correct inaccuracies in your personal data</p>
                </li>
                <li>
                    <p>Right to request deletion of your personal data</p>
                </li>
                <li>
                    <p>Right to obtain a copy of the personal data you previously shared with us</p>
                </li>
                <li>
                    <p>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</p>
                </li>
            </ul>
            <p>We sell personal data to third parties or process personal data for targeted advertising. Please see the following section to find out how you can opt out from further selling or sharing of your personal data for targeted advertising or profiling purposes.</p>
            <h6><u>Exercise your rights provided under the Virginia VCDPA</u></h6>
            <p>You can opt-out from the selling of your personal data, targeted advertising, or profiling by disabling cookies in Cookie Preference Settings. You may contact us by email at hi@traaple.com or visit&nbsp;<a href="https://getodeen.com/privacy/data-request"><u>https://traaple.com/privacy/data-request</u></a>.</p>
            <p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>
            <h6><u>Verification process</u></h6>
            <p>We may request that you provide additional information reasonably necessary to verify your and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.</p>
            <p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</p>
            <h6><u>Right to appeal</u></h6>
            <p>If we decline to take action regarding your request, we will inform you of our decision and the reasoning behind it. If you wish to appeal our decision, please email us at hi@traaple.com. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the&nbsp;<a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"><u>Attorney General to submit a complaint</u></a>.</p>
            <ol start="14">
                <li>
                    <ul>
                        <li>
                            <h4>DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <h5><strong>Australia and New Zealand</strong></h5>
            <p>We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).</p>
            <p>This privacy notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.</p>
            <p>If you do not wish to provide the personal information necessary to fulfill their applicable purpose, it may affect our ability to provide our services, in particular:</p>
            <ul>
                <li>
                    <p>offer you the products or services that you want</p>
                </li>
                <li>
                    <p>respond to or help with your requests</p>
                </li>
                <li>
                    <p>manage your account with us</p>
                </li>
                <li>
                    <p>confirm your identity and protect your account</p>
                </li>
            </ul>
            <p>At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"</p>
            <p>If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy Principles to the&nbsp;<a href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"><u>Office of the Australian Information Commissioner</u></a>&nbsp;and a breach of New Zealand's Privacy Principles to the&nbsp;<a href="https://www.privacy.org.nz/your-rights/making-a-complaint/"><u>Office of New Zealand Privacy Commissioner</u></a>.</p>
            <h5><strong>Republic of South Africa</strong></h5>
            <p>At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"</p>
            <p>If you are unsatisfied with the manner in which we address any complaint with regard to our processing of personal information, you can contact the office of the regulator, the details of which are:</p>
            <p><a href="https://inforegulator.org.za/"><u>The Information Regulator (South Africa)</u></a></p>
            <p>General enquiries: enquiries@inforegulator.org.za</p>
            <p>Complaints (complete POPIA/PAIA form 5): PAIAComplaints@inforegulator.org.za &amp; POPIAComplaints@inforegulator.org.za</p>
            <ol start="15">
                <li>
                    <ul>
                        <li>
                            <h4>DO WE MAKE UPDATES TO THIS NOTICE?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
            <ol start="16">
                <li>
                    <ul>
                        <li>
                            <p>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>If you have questions or comments about this notice, you may email us at hi@traaple.com or contact us by post at:</p>
            <p>Traaple Inc.</p>
            <p>2810 N Church St PMB 75146</p>
            <p>Wilmington, DE 19802</p>
            <p>United States</p>
            <ol start="17">
                <li>
                    <ul>
                        <li>
                            <h4>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>
                        </li>
                    </ul>
                </li>
            </ol>
            <p>You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit&nbsp;<a href="https://getodeen.com/privacy/data-request"><u>https://traaple.com/privacy/data-request</u></a>.</p>
            <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at hi@traaple.com.</p>
        </div>
    );
}

export default PrivacyPolicy;
