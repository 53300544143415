import React from 'react';
import './Button.css'; // Import CSS for styling

const Button = ({ onClick, radius, bgcolor, textcolor, text, buttonType, size="auto", disabled = false, isloading = false }) => {
  const buttonStyle = {
    borderRadius: `${radius}px`,
    backgroundColor: buttonType === 'outline' ? 'transparent' : bgcolor,
    color: buttonType === 'outline' ? bgcolor : textcolor,
    border: buttonType === 'outline' ? `2px solid ${bgcolor}` : 'none',
    width: size === "auto" ? "auto" : size === "full" ? "100%" : "auto",
  };

  const LoadingDots = () => (
    <div className="loading-dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  return (
    <button
      className={`custom-button ${buttonType} ${disabled ? 'disabled' : ''} ${isloading ? 'loading' : ''}`}
      style={buttonStyle}
      onClick={() => {!isloading && onClick()}}
      disabled={disabled || isloading}
    >
      {isloading ? <div style={{ padding: '6px'}}><LoadingDots /></div> : text}
    </button>
  );
};
 
export default Button;
